<template>
  <BrToolBar @search="buscar" :configFilter="configFilter" :labelTextField="labelTextField" :disableFilter="false">
    <!-- <modal /> -->

    <template v-slot:btnSelect>
      <slot name="btnSelect"/>
    </template>
  </BrToolBar> 
</template>

<script>
/**
 * MIXIN
 */
import toolbar from '@/views/mixins/toolbar'

export default {
  props: ['select'],
  name: `BrToolBarCadastroRole`,
  mixins: [toolbar],
  components: { 
    BrToolBar: () => import('@/views/components/toolbar/toolbar2'),
    // modal: () => import('./modal')
  }
}
</script>